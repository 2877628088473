import React from "react";

import { faAngleLeft } from "@fortawesome/sharp-regular-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/sharp-regular-svg-icons/faAngleRight";
import { faAnglesLeft } from "@fortawesome/sharp-regular-svg-icons/faAnglesLeft";
import { faAnglesRight } from "@fortawesome/sharp-regular-svg-icons/faAnglesRight";

import { createInternalLink } from "~utils/link_utils/createInternalLink";
import { createUrlPathFromArray } from "~utils/path_utils/createUrlPathFromArray";

import { Box } from "~components/Box";
import { ButtonLink } from "~components/ButtonLink";

import { getPaginationArray } from "./helper_functions/get_pagination_array/get_pagination_array";

import type { ButtonLinkProps } from "~components/ButtonLink";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

export interface PaginationProps extends GetSprinklesArgs {
  basePath: string;
  currentPage: number;
  pageCount: number;
  anchorLinkId?: `#${string}`;
}

const BUTTON_PROPS: ButtonLinkProps = {
  appearance: "squared",
  size: "md",
  shouldHaveArrowIcon: false,
};

/** Renders pagination links/links for navigating paginated lists. */
export function Pagination({
  basePath,
  currentPage,
  pageCount,
  anchorLinkId,
  ...rest
}: PaginationProps) {
  const isFirstOrPrevDisabled = currentPage <= 1;
  const previousPath =
    currentPage - 1 > 1
      ? createUrlPathFromArray([
          basePath,
          (currentPage - 1).toString(),
          anchorLinkId,
        ])
      : basePath;

  const nextPath = createUrlPathFromArray([
    basePath,
    (currentPage + 1).toString(),
    anchorLinkId,
  ]);
  const lastPath =
    typeof pageCount === "number" &&
    createUrlPathFromArray([basePath, pageCount.toString(), anchorLinkId]);

  const isNextOrLastDisabled = currentPage >= pageCount;

  const pageArray = getPaginationArray({ pageCount, currentPage });

  if (pageCount <= 1) {
    return null;
  }

  return (
    <Box
      as="nav"
      display="flex"
      gap="spacing1"
      justifyContent="center"
      marginY="spacing6"
      {...rest}
    >
      {!isFirstOrPrevDisabled && (
        <>
          <ButtonLink
            link={createInternalLink(basePath)}
            {...BUTTON_PROPS}
            iconLeft={faAnglesLeft}
            data-testid="pagination-button-first"
          />
          <ButtonLink
            link={createInternalLink(previousPath)}
            {...BUTTON_PROPS}
            iconLeft={faAngleLeft}
            data-testid="pagination-button-previous"
          />
        </>
      )}

      {/** "go to page n" buttons */}
      {pageArray.length > 0 &&
        pageArray.map((page) => {
          const isFirst = page === 1;

          const link = isFirst
            ? basePath
            : createUrlPathFromArray([basePath, page.toString(), anchorLinkId]);

          return (
            <ButtonLink
              link={createInternalLink(link)}
              key={page.toString()}
              isCurrentPage={page === currentPage}
              {...BUTTON_PROPS}
            >
              {page.toString()}
            </ButtonLink>
          );
        })}

      {!isNextOrLastDisabled && (
        <>
          <ButtonLink
            link={createInternalLink(nextPath)}
            {...BUTTON_PROPS}
            iconRight={faAngleRight}
            data-testid="pagination-button-next"
          />
          {lastPath && (
            <ButtonLink
              link={createInternalLink(lastPath)}
              {...BUTTON_PROPS}
              iconRight={faAnglesRight}
              data-testid="pagination-button-last"
            />
          )}
        </>
      )}
    </Box>
  );
}
