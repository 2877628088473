import React from "react";

import { faArrowLeft } from "@fortawesome/sharp-regular-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/sharp-regular-svg-icons/faArrowRight";

import { Box } from "~components/Box";
import { Button } from "~components/Button";
import { getPaginationArray } from "~components/Pagination/helper_functions/get_pagination_array/get_pagination_array";

import * as styles from "./style.css";

import type { ButtonProps } from "~components/Button";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

export interface PaginationSinglePageProps extends GetSprinklesArgs {
  currentPage: number;
  pageCount: number;
  onPageChange: (page: number) => void;
}

const BUTTON_PROPS: ButtonProps = {
  appearance: "squared",
  size: "md",
};

export function PaginationSinglePage({
  currentPage,
  pageCount,
  onPageChange,
  ...rest
}: PaginationSinglePageProps) {
  const isPrevDisabled = currentPage <= 1;
  const isNextDisabled = currentPage >= pageCount;

  const pageArray = getPaginationArray({ pageCount, currentPage });

  if (pageCount < 1) {
    return null;
  }

  return (
    <Box
      as="nav"
      display="flex"
      gap="spacing1"
      justifyContent="center"
      marginY="spacing6"
      {...rest}
    >
      <Button
        {...BUTTON_PROPS}
        className={styles.prevNextButtonStyle}
        onClick={() => {
          return onPageChange(currentPage - 1);
        }}
        iconLeft={faArrowLeft}
        disabled={isPrevDisabled}
      />

      {/** "go to page n" buttons */}
      {pageArray.length > 0 &&
        pageArray.map((page) => {
          return (
            <Button
              onClick={() => {
                return onPageChange(page);
              }}
              {...BUTTON_PROPS}
              key={page.toString()}
              isCurrentPage={page === currentPage}
            >
              {page.toString()}
            </Button>
          );
        })}

      <Button
        {...BUTTON_PROPS}
        className={styles.prevNextButtonStyle}
        onClick={() => {
          return onPageChange(currentPage + 1);
        }}
        iconRight={faArrowRight}
        disabled={isNextDisabled}
      />
    </Box>
  );
}
