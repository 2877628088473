import React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import { faArrowRightLong } from "@fortawesome/sharp-regular-svg-icons/faArrowRightLong";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { Box } from "~components/Box";
import { IconBrand } from "~components/IconBrand";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { Link } from "~components/Link";
import { StoryblokRichText } from "~components/StoryblokRichText";

import * as styles from "./styles.css";

import type { IconBrandProps } from "~components/IconBrand";
import type { LinkProps } from "~components/Link";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type {
  StoryblokBlok,
  StoryblokFieldLink,
  StoryblokFieldRichText,
} from "~types/storyblok.types";

export type LinkListItemSubComponent =
  | "icon"
  | "ctaText"
  | "description"
  | "title"
  | "prefix";

export interface LinkListItemProps
  extends Omit<LinkProps, "children" | "prefix" | "title">,
    Omit<GetSprinklesArgs, "children" | "color">,
    StoryblokBlok {
  brandIcon?: IconBrandProps["icon"] | null;
  description?: string | StoryblokFieldRichText;
  link?: StoryblokFieldLink;
  linkToExternalPage?: StoryblokFieldLink;
  prefix?: string;
  subComponents?: Array<LinkListItemSubComponent>;
  title?: string;
}

const DEFAULT_SUB_COMPONENTS: Array<LinkListItemSubComponent> = [
  "icon",
  "description",
  "title",
  "prefix",
];

/** Renders a styled card that can be passed a link. */
export function LinkListItem({
  brandIcon,
  className: userClassName,
  description,
  link,
  linkToExternalPage,
  prefix,
  subComponents = DEFAULT_SUB_COMPONENTS,
  title,
  ...rest
}: LinkListItemProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  const cardWrapperClassNames = clsx(
    userClassName,
    getSprinkles({ ...atomProps }),
    styles.linkListItemWrapper
  );

  return (
    <Link
      fallbackElement="div"
      link={linkToExternalPage || link}
      className={cardWrapperClassNames}
      {...otherProps}
    >
      {/* Brand icon */}
      {brandIcon && subComponents.includes("icon") && (
        <div className={styles.iconWrapper}>
          <IconBrand width="spacing4" icon={brandIcon} />
        </div>
      )}

      <Box display="flex" flexDirection="column" gap="spacing2">
        {prefix && subComponents.includes("prefix") && (
          <div className={styles.prefix}>{prefix}</div>
        )}

        {title && subComponents.includes("title") && (
          <div className={styles.title}>{title}</div>
        )}

        {description && subComponents.includes("description") && (
          <StoryblokRichText
            className={styles.description}
            text={description}
          />
        )}
      </Box>

      <Box marginLeft="auto" className={styles.iconWrapper}>
        <IconFontAwesome className={styles.brandIcon} icon={faArrowRightLong} />
      </Box>
    </Link>
  );
}
