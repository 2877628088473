import React from "react";

import { faSpinner } from "@fortawesome/sharp-regular-svg-icons/faSpinner";

import { IconFontAwesome } from "~components/IconFontAwesome";

import type { IconFontAwesomeProps } from "~components/IconFontAwesome";

export interface LoaderProps extends Omit<IconFontAwesomeProps, "icon"> {
  icon?: IconFontAwesomeProps["icon"];
}

export function Loader({ icon = faSpinner, ...rest }: LoaderProps) {
  return (
    <IconFontAwesome
      size="2x"
      alignItems="center"
      display="flex"
      icon={icon}
      color="text_lowContrast"
      justifyContent="center"
      spin
      width="100%"
      {...rest}
    />
  );
}
