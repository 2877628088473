import React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { Box } from "~components/Box";
import { IconBrand } from "~components/IconBrand";
import { Link } from "~components/Link";

import * as styles from "./styles.css";

import type { IconBrandProps } from "~components/IconBrand";
import type { LinkProps } from "~components/Link";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { StoryblokBlok, StoryblokFieldLink } from "~types/storyblok.types";

export interface NavListItemProps
  extends Omit<LinkProps, "children" | "prefix" | "title">,
    Omit<GetSprinklesArgs, "color">,
    StoryblokBlok {
  brandIcon?: IconBrandProps["icon"] | null;
  description?: string;
  link?: StoryblokFieldLink;
  title?: string;
}

/** Renders a styled card that can be passed a link. */
export function NavListItem({
  brandIcon,
  className: userClassName,
  description,
  link,
  title,
  ...rest
}: NavListItemProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  const cardWrapperClassNames = clsx(
    userClassName,
    getSprinkles({ ...atomProps }),
    styles.getNavListItemStyles({})
  );

  return (
    <Link
      fallbackElement="div"
      link={link}
      className={cardWrapperClassNames}
      {...otherProps}
    >
      {/* Brand icon */}
      {brandIcon && (
        <IconBrand
          width="spacing3"
          height="spacing3"
          icon={brandIcon}
          color="accent_base"
        />
      )}

      <div>
        {/* Title */}
        {title && (
          <Box textAppearance="body_md" className={styles.title}>
            {title}
          </Box>
        )}
        {description && (
          <Box textAppearance="body_sm" color="text_lowContrast">
            {description}
          </Box>
        )}
      </div>
    </Link>
  );
}
