import React, { forwardRef } from "react";

import { Link as GatsbyLink } from "gatsby";

import { getLinkProps } from "~utils/link_utils/getLinkProps";
import { smoothScrollToElement } from "~utils/link_utils/smoothScrollToElement";
import { getIsInEditor } from "~utils/storyblok/getIsInEditor";

import type { GatsbyLinkProps } from "gatsby";
import type { ElementType, ReactNode, Ref } from "react";
import type { StoryblokFieldLink } from "~types/storyblok.types";

export interface LinkProps
  extends Omit<GatsbyLinkProps<Record<string, unknown>>, "ref" | "to" | "key"> {
  /** Link node to wrap with link */
  children?: ReactNode;
  /** Link object */
  link?: StoryblokFieldLink;
  /** Url for external link */
  href?: string;
  /** Html id for the element */
  id?: string;
  /** Html button type, if returning a button */
  type?: string;

  fallbackElement?: ElementType;
}

/**
 * - Wraps Gatsby's Link component and extends it to understand links defined in Storyblok.
 * - Parses link object (from Storyblok link field or programmatically created)
 * and returns either `GatsbyLink` or an anchor element
 */
export const Link = forwardRef(
  (
    { link, children, title, fallbackElement = "span", ...rest }: LinkProps,
    ref: Ref<HTMLAnchorElement> | undefined
  ) => {
    const FallbackElement = fallbackElement || "span";

    const { linkType, target, to, rel, href } = getLinkProps(link) || {};

    if (link && getIsInEditor()) {
      return <a {...rest}>{children}</a>;
    }

    if (linkType === "internalLink" && to) {
      const handleSmoothScroll = () => {
        smoothScrollToElement(to);
      };

      return (
        <GatsbyLink
          title={title}
          target={target}
          to={to}
          rel={rel}
          innerRef={ref}
          onClick={handleSmoothScroll}
          {...rest}
        >
          {children}
        </GatsbyLink>
      );
    }
    if (linkType === "externalLink" && href) {
      return (
        <a
          title={title}
          target={target}
          href={href}
          rel={rel}
          ref={ref}
          {...rest}
        >
          {children}
        </a>
      );
    }

    return <FallbackElement {...rest}>{children}</FallbackElement>;
  }
);
