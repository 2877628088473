import React from "react";

import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import * as Separator from "@radix-ui/react-separator";
import clsx from "clsx";

import { Button } from "~components/Button";
import { StoryblokRichText } from "~components/StoryblokRichText";

import { Link } from "../Link/index";
import * as styles from "./styles.css";

import type { ComponentMap } from "~types/global.types";
import type {
  StoryblokBlok,
  StoryblokFieldLink,
  StoryblokFieldRichText,
} from "~types/storyblok.types";

export interface NavCallOutProps extends StoryblokBlok {
  description?: string | StoryblokFieldRichText;
  cta?: string;
  link?: StoryblokFieldLink;
  darkMode?: boolean;
  fullBackground?: boolean;
  device?: "mobile" | "desktop";
}

export function NavCallOut(
  this: ComponentMap,
  {
    description,
    cta,
    link,
    darkMode,
    fullBackground,
    device = "mobile",
  }: NavCallOutProps
) {
  const isDesktop = device === "desktop";
  const padding = fullBackground && isDesktop ? "full" : "standard";
  const colorMode = darkMode ? "dark" : "light";

  return (
    <NavigationMenu.Item asChild>
      <Link
        id="callOutContainer"
        link={link}
        className={clsx(styles.getCallOutTextStyles({ colorMode }))}
      >
        {padding === "standard" && (
          <Separator.Root
            id="callOutSeparator"
            className={styles.callOutSeparator}
          />
        )}
        <div
          id="callOutWrapper"
          className={styles.getCallOutWrapperStyles({ padding })}
        >
          <div
            id="callOutBackground"
            className={clsx(
              styles.getCallOutTextStyles({ colorMode }),
              styles.getCallOutBackgroundStyles({ colorMode, padding })
            )}
          >
            <StoryblokRichText
              id="descriptionWrapper"
              className={styles.callOutDescriptionWrapper}
              text={description}
            />
            <Button
              appearance="tertiary"
              marginLeft="auto"
              size="lg"
              className={clsx(styles.getCallOutTextStyles({ colorMode }))}
            >
              {isDesktop && cta}
            </Button>
          </div>
        </div>
      </Link>
    </NavigationMenu.Item>
  );
}
