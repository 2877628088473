import React from "react";

import { calc } from "@vanilla-extract/css-utils";

import { vars } from "~styles/themes/theme.css";

import { Box } from "~components/Box";

import type { BoxProps } from "~components/Box";

export type QuoteIconFloatingProps = BoxProps;

export function QuoteIconFloating({ ...rest }: QuoteIconFloatingProps) {
  return (
    <Box
      __fontSize={vars.gridSpan.gridSpan2}
      __lineHeight="0.775"
      __right={calc.add("100%", vars.spacing.spacing3)}
      color="accent_tint"
      position="absolute"
      textAlign="center"
      width="gridSpan1"
      // zIndex="-1"
      {...rest}
    >
      “
    </Box>
  );
}
