import React from "react";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { LinkListItem, type LinkListItemProps } from "./LinkListItem";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface LinkListProps extends BoxProps, StoryblokBlok {
  linkListItems?: Array<StoryblokBlok & LinkListItemProps>;
  className?: string;
  subComponents?: LinkListItemProps["subComponents"];
  isTitlePrefixReversed?: boolean;
}

export function LinkList({
  className: userClassName,
  linkListItems,
  subComponents,
  isTitlePrefixReversed: __isTitlePrefixReversed, // deprecated
  ...rest
}: LinkListProps) {
  return (
    <Box className={userClassName} {...rest}>
      {Array.isArray(linkListItems) &&
        linkListItems?.map((item) => {
          return (
            <StoryblokEditable key={item.title || item.prefix} {...item}>
              <LinkListItem
                subComponents={subComponents}
                {...item}
                key={item.title || item.prefix}
                title={item.title}
                prefix={item.prefix}
              />
            </StoryblokEditable>
          );
        })}
    </Box>
  );
}
