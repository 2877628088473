interface GetPaginationArrayParams {
  /** Total number of pages. */
  pageCount: number;
  /** Number of current page. */
  currentPage: number;
}

/**
 * Takes a page count and current page number and returns an array
 * that can be used to map for pagination links. Returns numbers in a range
 * above and below the current page number.
 */
export function getPaginationArray({
  pageCount,
  currentPage,
}: GetPaginationArrayParams) {
  let lowerBoundary = 1;
  let upperBoundary = currentPage + 2;

  // Create array from pageCount, add 1 to each item to offset 0 index
  const array = Array(pageCount)
    .fill(1)
    .map((_, index) => {
      return index + 1;
    });

  // Show 5 pagination links at most
  if (pageCount <= 5) {
    return array;
  }

  if (currentPage - 3 > 1) {
    lowerBoundary = currentPage - 3;
  }

  if (currentPage > pageCount - 3) {
    lowerBoundary = pageCount - 5;
  }

  if (currentPage + 2 >= pageCount) {
    upperBoundary = pageCount;
  }

  if (currentPage >= 5) {
    return array.slice(lowerBoundary, upperBoundary);
  }

  return array.slice(0, 5);
}
