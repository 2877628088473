import React from "react";

import { faArrowRightLong } from "@fortawesome/sharp-regular-svg-icons/faArrowRightLong";

import { Box } from "~components/Box";
import { ButtonLink } from "~components/ButtonLink";
import { StoryblokRichText } from "~components/StoryblokRichText";
import { SvgLogo } from "~components/SvgLogo";
import { VStack } from "~components/VStack";

import { Avatar } from "../Avatar";
import { QuoteIconFloating } from "./QuoteIconFloating";

import type { ImageProps } from "../Image";
import type { BoxProps } from "~components/Box";
import type {
  StoryblokBlok,
  StoryblokFieldLink,
  StoryblokFieldRichText,
} from "~types/storyblok.types";

export interface QuoteProps
  extends Omit<BoxProps, "size" | "background">,
    StoryblokBlok {
  attribution?: StoryblokFieldRichText;
  avatar?: Array<StoryblokBlok & ImageProps>;
  link?: StoryblokFieldLink;
  ctaText?: string;
  isLogoVisible?: boolean;
  isIconVisible?: boolean;
  logo?: string;
  quote?: StoryblokFieldRichText | string;
}

export function Quote({
  attribution,
  avatar: avatarBlokArray,
  className: userClassName,
  link,
  ctaText,
  isLogoVisible = true,
  isIconVisible = true,
  logo,
  quote,
  ...rest
}: QuoteProps) {
  const [avatar] = avatarBlokArray || [];

  return (
    <VStack
      className={userClassName}
      as="blockquote"
      role="blockquote"
      maxWidth="gridSpan8"
      position="relative"
      marginY="spacing2"
      {...rest}
    >
      {/** Quote icon */}
      {isIconVisible && <QuoteIconFloating />}

      {/**
       * --------------------------------------------
       * Body of the quote
       * -----------------------------------------------
       */}
      {quote && (
        <StoryblokRichText
          color="text_highContrast"
          textAppearance="h5"
          fontWeight="semibold"
          lineHeight="title"
          gap="none"
          text={quote}
        />
      )}

      {/**
       * ----------------------------------------------
       * Quote attribution
       * ----------------------------------------------
       */}

      {/** Attribution images */}
      {(attribution || logo) && (
        <Box marginTop="auto" display="flex" gap="spacing2">
          {avatar?.image && (
            <Avatar width="spacing10" height="spacing10" image={avatar.image} />
          )}

          {/** Attribution text */}
          <Box>
            {attribution && (
              <StoryblokRichText
                color="accent_base"
                textAppearance="body_md"
                rowGap="none"
                text={attribution}
              />
            )}
            {isLogoVisible && logo && (
              <SvgLogo svgLogo={logo} height="spacing6" color="accent_base" />
            )}
          </Box>
        </Box>
      )}
      {/* Link */}
      {link && ctaText && (
        <ButtonLink
          iconLeft={faArrowRightLong}
          link={link}
          appearance="tertiary"
        >
          {ctaText}
        </ButtonLink>
      )}
    </VStack>
  );
}
