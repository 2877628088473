import * as React from "react";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { SvgLogo } from "~components/SvgLogo";

import type { LogoListProps } from ".";
import type { BoxProps } from "~components/Box";

export type LogoListListProps = BoxProps &
  Pick<LogoListProps, "logos" | "logoHeight"> & { logoListIndex: number };

/** Util to sort logos alphabetically */
const sortLogos = (a: string, b: string) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }

  return 0;
};

/** Renders a list of logos. */
export function LogoListList({
  logos,
  logoHeight,
  logoListIndex,
  ...rest
}: LogoListListProps) {
  if (!Array.isArray(logos)) return null;

  const sortedLogos = logos.sort(sortLogos);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Box {...rest}>
      {Array.isArray(sortedLogos) &&
        sortedLogos.map((logo) => {
          return (
            <SvgLogo
              data-motion={ANIMATED.primary}
              key={`${logoListIndex}-${logo}`}
              color="logo_color"
              svgLogo={logo}
              height={logoHeight}
              flexShrink="0"
            />
          );
        })}
    </Box>
  );
}
