import React, { forwardRef } from "react";

import clsx from "clsx";

import { vars } from "~styles/themes/theme.css";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "../Box";
import * as styles from "./styles.css";

import type { BoxProps } from "../Box";
import type { ReactNode, Ref } from "react";

export interface PhoneMockupProps extends BoxProps {
  children?: ReactNode;
  className?: string;
}
export const PhoneMockup = forwardRef(
  (
    {
      children,
      maxWidth = "gridSpan3",
      className: userClassName,
      width = "100%",
      ...rest
    }: PhoneMockupProps,
    ref: Ref<HTMLElement>
  ) => {
    return (
      <Box
        ref={ref}
        maxWidth={maxWidth}
        borderRadius="md"
        __border={`${vars.spacing.spacing0} solid ${vars.color.neutral.black}`}
        overflow="hidden"
        position="relative"
        className={clsx(
          userClassName,
          variantBackgroundColor.background_white,
          styles.phoneMockup
        )}
        width={width}
        aspectRatio="mobile"
        {...rest}
      >
        {children}
      </Box>
    );
  }
);
